import moment from "moment";
import getUrl from "../modules/getUrl";

class Calendar {
    constructor() {
        this.moment = moment();
        this.setLanguage('en');
        this.currentDay = this.moment.clone();
        this.month = this.moment;
        this.calendar = [];
        this.events = [];
        this.locations = [];
        this.selectedDay = false;
        this.selectedEvent = false;
        this.todaysEvents = [];
        this.location = '';
        this.reservation = 0;
        this.numPeople = 1;
        this.loading = true;
        this.bookableResourceID = '';
    }

    setLanguage(lang) {
        this.language = lang;
        this.moment.locale(lang);
    }

    get() {
        let startDay = this.month.clone().startOf('month');
        let endDay = this.month.clone().endOf('month');
        let calendarStart = startDay.clone().startOf('month').day(0);
        let calendarEnd = endDay.clone().endOf('month').day(6);
        let month = [];
        let week = [];

        // Now we have the first Sunday to show, we need to create a "week" array for every week of the month, starting from calendarStart
        while (calendarStart.isBefore(calendarEnd)) {
            // A new week starts every Sunday
            if (calendarStart.day() == 0) {
                // If we already have a full week, add it to the month
                if (week.length > 0) {
                    month.push(week);
                }
                week = [];
            }
            // Push the day info onto the week
            week.push({
                id: calendarStart.format(),
                dayOfWeek: calendarStart.day(),
                inMonth: !(calendarStart.isBefore(startDay) || calendarStart.isAfter(endDay)),
                day: calendarStart.date(),
                isToday: calendarStart.isSame(this.currentDay, 'day'),
                events: [],
            });
            // Add a day
            calendarStart.add(1, 'd');
        }
        // Push the final week onto the month
        month.push(week);

        return month;
    }

    update() {
        this.calendar = this.get();
        this.getEvents();
    }

    getMonthName() {
        return this.month.format('MMM YYYY');
    }

    nextMonth() {
        this.month.add(1, 'months').month();
        this.update();
    }

    previousMonth() {
        if (this.month.isSame(this.currentDay, 'year') && this.month.isSame(this.currentDay, 'month')) {
            return false;
        }
        this.month.subtract(1, 'months').month();
        this.update();
    }

    addEventToCalendar(date, event)
    {
        date = date[0];
        this.calendar.forEach((week, weekIndex) => {
            week.forEach((day, dayIndex) => {
                if (day.id == date) {
                    this.calendar[weekIndex][dayIndex].events.push(event);
                }
            });
        });
    }

    populateTodaysEvents() {
        this.todaysEvents = [];
        this.events.forEach((event, index) => {
            // Check each of the days for the event
            event.days.forEach(date => {
                if (date == this.selectedDay.id) {
                    this.todaysEvents.push(event);
                }
            });
        });
        // Check if our current selected event is part of today's events.
        // Because if it's not, we need to reset it
        let selectedEventStillValid = false;
        this.todaysEvents.forEach(event => {
            if (event.id == this.selectedEvent.id) {
                selectedEventStillValid = true;
            }
        });
        // If we didn't find a valid event, set selected event to the first event if there are any, or false if nothing available for the day
        if (!selectedEventStillValid) {
            this.selectedEvent = (this.todaysEvents.length == 0) ? false : this.todaysEvents[0];
        }
    }

    selectDay(day) {
        let firstEvent = false;
        this.selectedEvent = false;
        this.selectedDay = day;
        // Set today's events
        this.populateTodaysEvents();

        // Set the first event for the day as selected
        if (this.todaysEvents.length > 0) {
            this.selectedEvent = this.todaysEvents[0];
            this.selectedEvent.selected = true;
        }
    }

    selectEvent(selectedEvent) {
        this.selectedEvent = selectedEvent;
        this.events.forEach((event, index) => {
            this.events[index].selected = (event.id == selectedEvent.id) ? true : false;
        });
        this.todaysEvents.forEach((event, index) => {
            this.todaysEvents[index].selected = (event.id == selectedEvent.id) ? true : false;
        });
    }

    minimumFiltersSet() {
        if (this.numPeople <= 0) {
            return false;
        }
        if (this.reservation <= 0) {
            return false;
        }
        if (this.bookableResourceID == '') {
            return false;
        }
        return true;
    }

    getEvents() {
        if (!this.minimumFiltersSet()) {
            console.log("minimum data isn't set", this);
            this.events = [];
            return false;
        }

        const data = {
            calendar: this.calendar,
            lesson: 'Private Foundations Lesson',
            bookableResourceID: this.bookableResourceID,
            numPeople: this.numPeople,
            lang: this.language,
        };
        this.loading = true;

        axios.post(getUrl() + 'api/private-foundation-events', data)
        .then(response => {
            this.events = [];
            let firstEvent = true;

            // Iterate over the days in the response
            response.data.forEach(event => {
                event.selected = firstEvent;
                firstEvent = false;
                this.events.push(event);
            });
            this.populateTodaysEvents();
        })
        .catch(error => {
            console.error("resources/js/classes/PrivateFoundationCalendarClass.js@getEvents error", error);
        })
        .finally(() => {
            this.loading = false;
        });
    }

    setReservation(reservation) {
        if (this.reservation !== reservation) {
            this.loading = true;

            this.reservation = 0;
            this.bookableResourceID = 0;
            this.selectedResource = false;
            this.events = [];

            axios.post(getUrl() + 'api/private-foundation-lesson', {
                id: reservation
            })
            .then(response => {
                this.reservation = reservation;
                this.bookableResourceID = response.data.planyo_id || 0;
                this.selectedResource = response.data || false;
                this.getEvents();
            })
            .catch(error => {
                console.error("resources/js/classes/PrivateFoundationCalendarClass.js@setReservation error", error);
            })
            .finally(() => {
                this.loading = false;
            });
        }
    }
}

export default Calendar;